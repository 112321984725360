import React from "react"
import { Link } from "@reach/router"

import { useStaticQuery, graphql, navigate } from "gatsby"

import moment from "moment"

function gotoPage(url) {
  navigate(url)
}

const BlogPost = () => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        posts(first: 3) {
          edges {
            node {
              id
              title
              slug
              excerpt
              date
              featuredImage {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)

  // console.log(data)
  return (
    <div className="mb-5 mt-5">
      <style jsx>{`
        .post-meta {
          color: grey;
        }
      `}</style>
      <div className="news-news-grid">
        {data.wpgraphql.posts.edges.map(post => (
          <div className="post-preview home-news-item" key={post.node.id}>
            <Link
              state={{ postId: post.node.id }}
              to={`/news/${post.node.slug}`}
            >
              <h4
                className="post-title"
                dangerouslySetInnerHTML={{
                  __html: post.node.title,
                }}
              ></h4>
            </Link>
            {/*<p*/}
            {/*  className="post-subtitle"*/}
            {/*  dangerouslySetInnerHTML={{*/}
            {/*    __html: post.node.excerpt,*/}
            {/*  }}*/}
            {/*></p>*/}
            <p className="post-meta">
              {/*Posted by*/}
              {/*<a href="#">&nbsp;{{post.node.author}}&nbsp;</a>*/}
              <span>Posted </span>
              {moment(post.node.date).calendar()}
            </p>
          </div>
        ))}
      </div>
      <button type="button" className={`btn more-btn`}>
        <Link to="/news">Read More Updates</Link>
      </button>
    </div>
  )
}

export default BlogPost
