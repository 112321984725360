import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Slider from "../components/slider/index"
import BlogPost from "../components/blog"
import SEO from "../components/seo"
import UniversalLink from "../components/UniversalLink"
import { createLocalLink } from "../utils"

// import BlogStorySection from "../components/home/"
// import {
//   Midsection,
//   EasyOrder,
//   ExploreProducts,
//   BlogStorySection,
//   TestimonalSection
// } from '../components/home/index'
class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.headingTitles = [props.data.wpgraphql.siteOptions.homeHero.heroTitle]
    this.subTitle = props.data.wpgraphql.siteOptions.homeHero.heroText
    this.buttons = [
      {
        text: props.data.wpgraphql.siteOptions.homeHero.linkText,
        url: createLocalLink(
          props.data.wpgraphql.siteOptions.homeHero.link.url
        ),
        cls: `hero-btn`,
      },
    ]

    this.studentsSubtitle =
      props.data.wpgraphql.siteOptions.homeIntro.studentSubtitle
    this.studentsTitle = props.data.wpgraphql.siteOptions.homeIntro.studentTitle
    this.studentsSrc =
      props.data.wpgraphql.siteOptions.homeIntro.studentImage.imageFile.publicURL
    this.studentLinkUrl =
      props.data.wpgraphql.siteOptions.homeIntro.studentLink.url

    this.proSubtitle =
      props.data.wpgraphql.siteOptions.homeIntro.professionalSubtitle
    this.proTitle = props.data.wpgraphql.siteOptions.homeIntro.professionalTitle
    this.proSrc =
      props.data.wpgraphql.siteOptions.homeIntro.professionalImage.imageFile.publicURL
    this.proLinkUrl =
      props.data.wpgraphql.siteOptions.homeIntro.professionalLink.url

    this.orgSubtitle =
      props.data.wpgraphql.siteOptions.homeIntro.organizationSubtitle
    this.orgTitle = props.data.wpgraphql.siteOptions.homeIntro.organizationTitle
    this.orgSrc =
      props.data.wpgraphql.siteOptions.homeIntro.organizationImage.imageFile.publicURL
    this.orgLinkUrl =
      props.data.wpgraphql.siteOptions.homeIntro.organizationLink.url

    this.members = props.data.wpgraphql.siteOptions.homeMembers.members
  }

  render() {
    return (
      <Layout>
        <Slider
          headingTitles={this.headingTitles}
          subTitle={this.subTitle}
          buttons={this.buttons}
        />
        <div className="page-container">
          <div className="intro-section">
            <div className="container">
              <h2 className="hometitle">Equity Alliance MN</h2>
              <p className="homep">
                Equity Alliance MN is working to make the educational ecosystem
                across Minnesota equitable for every single student.
              </p>

              <div className="image-container">
                <figure className="hero-grid bluebg effect-move">
                  <UniversalLink to={createLocalLink(this.studentLinkUrl)}>
                    <img
                      className="hero-grid-image effect-image"
                      src={this.studentsSrc}
                      alt={this.studentsSrc}
                    />
                    <figcaption className="hero-grid-content">
                      <span className="hero-tag effect-target">
                        {this.studentsSubtitle}
                      </span>
                      <h3 className="hero-grid-title effect-target">
                        {this.studentsTitle}
                      </h3>
                    </figcaption>
                  </UniversalLink>
                </figure>

                <figure className="hero-grid greenbg effect-move">
                  <UniversalLink to={createLocalLink(this.proLinkUrl)}>
                    <img
                      className="hero-grid-image effect-image"
                      src={this.proSrc}
                      alt={this.proSrc}
                    />
                    <figcaption className="hero-grid-content">
                      <span className="hero-tag effect-target">
                        {this.proSubtitle}
                      </span>
                      <h3 className="hero-grid-title effect-target">
                        {this.proTitle}
                      </h3>
                    </figcaption>
                  </UniversalLink>
                </figure>

                <figure className="hero-grid orangebg effect-move onehundred">
                  <UniversalLink to={createLocalLink(this.orgLinkUrl)}>
                    <img
                      className="hero-grid-image effect-image"
                      src={this.orgSrc}
                      alt=""
                    />
                    <figcaption className="hero-grid-content">
                      <span className="hero-tag effect-target">
                        {this.orgSubtitle}
                      </span>
                      <h3 className="hero-grid-title effect-target">
                        {this.orgTitle}
                      </h3>
                    </figcaption>
                  </UniversalLink>
                </figure>
              </div>
            </div>
          </div>

          <div className="news-flexdiv">
            <div className="container">
              <div className="flex-text">
                <h3 className="news-h3">Latest News</h3>
                <BlogPost />
                {/*<BlogStorySection />*/}
              </div>
            </div>
          </div>
          {/*<TestimonalSection />*/}
          {/*<BlogStorySection />*/}
          <div className="members-flexdiv">
            <div className="container">
              <div className="flex-text">
                <h3 className="membertitle">Our Members</h3>
                <div className="members">
                  {this.members.map(node => (
                    // <UniversalLink to={node.url}>
                    <img
                      className="memberimg"
                      src={node.image.imageFile.publicURL}
                      alt={node.name}
                    />
                    //  </UniversalLink>
                  ))}
                </div>
                <Link className="hero-grid-link" to="#">
                  Learn about our funders and supporters
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query {
    wpgraphql {
      siteOptions {
        pageSlug
        pageTitle
        homeMembers {
          members {
            image {
              altText
              sourceUrl
              srcSet
              id
              imageFile {
                id
                publicURL
              }
              title
            }
            name
            url
          }
        }
        homeHero {
          heroTitle
          heroText
          linkText
          heroImage {
            id
            altText
            caption
            description
            mediaItemUrl
            sourceUrl
            srcSet
            title
            uri
            imageFile {
              id
            }
          }
          link {
            target
            title
            url
          }
        }
        homeIntro {
          sectionTitle
          sectionText
          studentTitle
          studentSubtitle
          studentLink {
            target
            title
            url
          }
          studentImage {
            altText
            caption
            description
            id
            imageFile {
              id
              publicURL
            }
            srcSet
            sourceUrl
            title
            uri
            mediaItemUrl
            link
          }
          professionalTitle
          professionalSubtitle
          professionalLink {
            target
            title
            url
          }
          professionalImage {
            altText
            caption
            id
            description
            link
            sourceUrl
            srcSet
            title
            uri
            imageFile {
              publicURL
              id
            }
          }
          organizationTitle
          organizationSubtitle
          organizationLink {
            target
            title
            url
          }
          organizationImage {
            altText
            caption
            description
            id
            imageFile {
              publicURL
              id
            }
            mediaItemUrl
            sourceUrl
            srcSet
            title
            uri
          }
        }
      }
    }
  }
`
